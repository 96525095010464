import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule, Routes } from '@angular/router';

import { FormsModule } from '@angular/forms';
import { ProgressBarComponent } from './progressbar/progressbar.component';
import { ModalAnimationComponent } from './modal-animation/modal-animation.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LazyLoadImageModule } from 'ng-lazyload-image'; 
import { NgOtpInputModule } from  'ng-otp-input';
import {TimeAgoPipe} from 'time-ago-pipe';

//import {DateSuffix} from './pipes/datesuffix.pipe';
//import{GetFirstWord} from './pipes/firstworld.pipe';
//import {CountDown} from './pipes/countdown.pipe';
import {SafeHtmlPipe} from './pipes/safehtml.pipe';

//import { ImageCropperModule } from 'ngx-image-cropper';
import { ShadowCssDirective } from './directives/shadowcss'; 

import { ProductcardSingleComponent } from './components/productcard-single/productcard-single';
import { AccordionsingleComponent } from './components/accordion-single/accordion-single';
import { DesktopfooterComponent } from './components/desktopfooter/desktopfooter';
import { PmcounterComponent } from './components/pmcounter-single/pmcounter';
import { OrdercardsingleComponent } from './components/ordercard-single/ordercard-single';
const routes: Routes = [
 
  {
    path: "happycustomer",component:DesktopfooterComponent
   
  }
];

@NgModule({
  declarations: [
    ProgressBarComponent,
    ModalAnimationComponent,
    ProductcardSingleComponent,
    DesktopfooterComponent,
    AccordionsingleComponent,
    PmcounterComponent,
    OrdercardsingleComponent,

    //pipes & Directives
    SafeHtmlPipe,
    ShadowCssDirective 
  ],
  imports: [
    CommonModule,
    IonicModule,
    NgSelectModule,
    FormsModule,
    RouterModule,
    LazyLoadImageModule ,
     [RouterModule.forChild(routes)],
  ],
  exports: [
    ProgressBarComponent,
    ModalAnimationComponent,
    NgSelectModule,
    FormsModule,
    CommonModule,
    LazyLoadImageModule, 
    NgOtpInputModule,
    RouterModule,
    DesktopfooterComponent,
    ProductcardSingleComponent,
    AccordionsingleComponent,
    PmcounterComponent,
    OrdercardsingleComponent,
     //pipes & Directives
     SafeHtmlPipe,
     ShadowCssDirective 

  ]
})
export class SharedModule {}
