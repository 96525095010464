import { Component, Input, Output, OnInit } from '@angular/core';
import { CommonService } from '../../../providers';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController, AlertController } from '@ionic/angular';
declare const _paq;

@Component({
  selector: 'app-productcard-single',
  templateUrl: './productcard-single.html',
  styleUrls: ['./productcard-single.scss']
})

export class ProductcardSingleComponent implements OnInit {

  @Input() options: any;
  item: any = {};
  items: any = [];
  searchspecies_id = 0;
  public isMenuOpen: boolean = false;

  lazyloaded = true;
  cartupdatetimeout: any;
  ccart: any;
  appsettings: any;
  currentUser: any;
  ischildcomponent: any;


  constructor(public commonService: CommonService,
    private alertCtrl: AlertController,
    public router: Router, public modalController: ModalController) {

  }


  ngOnInit(): void {
    if (this.options) {
       this.item = this.options.item;
      if(this.commonService.cart_isincart(this.item)){
        let cartitem = this.commonService.cart.items.filter(x => x._id == this.item._id);
        this.item['quantity'] = cartitem[0].quantity?cartitem[0].quantity:1;
      }
      
      if (this.options.searchspecies_id) {
        // console.log(this.options.searchspecies_id)
        this.searchspecies_id = this.options.searchspecies_id;
      }
    }
   // this.cartUpdate(0,this.items[0]);
  }




  cartUpdate(e,item) {
    console.log(e);
    if(this.commonService.cart_isincart(item)){
      if (this.cartupdatetimeout) {
        clearTimeout(this.cartupdatetimeout);
      }
      this.commonService.flag_canplaceorder = false;
      let i = this.commonService.cart.items.findIndex(x => x._id == item._id);
      console.log(i);
      this.commonService.cart.items[i].quantity = e;
          
      this.cartupdatetimeout = setTimeout(() => {
        // console.log(this.items[i]);
      if (this.commonService.cart.items[i]) {
        if (this.commonService.cart.items[i].quantity == 0) {
  
          _paq.push(['removeEcommerceItem', this.commonService.cart.items[i].id]);
          _paq.push(['trackEcommerceCartUpdate', this.commonService.cart.total]);
          this.commonService.cart.items.splice(i, 1);
          
        }
      }
      this.commonService.cart_update();
      this.ccart = this.commonService.cart_fetch();
      this.commonService.flag_canplaceorder = true;
  
      // console.log(this.ccart, "ccart");
      }, 1000);
    }
   
   
    this.checkoffercode();
  }
  checkoffercode() {
    // throw new Error('Method not implemented.');
  }

  buynow() {
    this.commonService.cart_add(this.item);
    let ccart = this.commonService.cart_total()-149;
    console.log(ccart);
    if(ccart <=500){
      this.commonService.notify('error', 'Please increase your order value to a minimum of Rs.500 excluding delivery charges', '', 'middle');
      //this.router.navigateByUrl('/');
      return 0;
    }
    if (this.commonService.cart_count() == 1 || this.commonService.isDesktop) {
      this.router.navigateByUrl('/checkout');
    } else {
      this.router.navigateByUrl('/cart');
    }
  }



  async popupmsg(item) {
    //console.log(item)
    let that = this;

    const alert = await this.alertCtrl.create({
      header: 'Confirm!',
      message: 'Dear customer - For ' + item.species_name + ' you cannot choose the same day delivery slot. Kindly continue to schedule a delivery for tomorrow or later.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
          }
        }, {
          text: 'Ok',
          handler: () => {
            this.commonService.cart_add(item);
          }
        }
      ]
    });

    alert.present();

  }

}