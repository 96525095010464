import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { environment } from '../../environments/environment';

import { ToastController, AlertController, LoadingController } from '@ionic/angular';
//import { base64ToFile } from 'ngx-image-cropper';


import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { Title,Meta } from '@angular/platform-browser';


import { FirebaseService } from './firebase.service';

import { Storage } from '@ionic/storage';
declare var jQuery: any;
declare const _paq;

@Injectable()
export class CommonService {
  apiUrl: string = environment.apiUrl;
  RapiUrl: string = environment.RapiUrl;
  currentUser: any = null;
  currentDevice: any = {};
  apiToken: any = '';
  tmppod: any;
  gpb = false;
  uid = '';
  loader: any = null;
  applanguage = "";
  cart: any = {};
  mincartvalueforfreedelivery: any = 5000;
  delivery_charge: any = 149;
  activecheckingorderid = 0;
  canhardwarebuttongoback = true;
  canshowshortbottomcart = false;
  isDesktop = false;
  addresses: any;
  availableMuttonDays: string[] = []; //storing the mutton avaibility days only in this array.
  

  isBrowser=false;
  areacodes: any = [];
  //total bill
  totalbill:any;
  is_express_delivery: boolean;
  public selectedparentspecies_id:any = 0;
  //    this.cart.items=[];
  public appmode = 'customer';
  flag_canplaceorder = true;
  ispnotification: boolean = true;
  tempemail: any;
  cctotal: number;
  cartItems: any;
  selectedDate: null;
  constructor(private http: HttpClient,
    public toastCtrl: ToastController,
    public metaService:Meta,
    public loadingCtrl: LoadingController,  public FirebaseService: FirebaseService,
    public storage: Storage, public location: Location, public titleService: Title,
    public alertCtrl: AlertController, public iab: InAppBrowser) {
      console.log(this.cart);
      let usermobile = 'guest';
      if (localStorage.getItem('currentUser')) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      }
      console.log(this.currentUser);
     
        usermobile = this.currentUser? this.currentUser.mobile : 'guest';
      
    
      if(localStorage.getItem(usermobile+'cart')){
          let lcart=localStorage.getItem(usermobile+'cart');
          console.log(this.cart,);
          console.log(JSON.parse(lcart));
          this.cart=JSON.parse(lcart);
      }else{
          //this.cart = { items: []};
      }
  }

  setPageTitle(sname, track = true) {
    //console.log('calling spage ', sname + ' - MeatRight');
    this.titleService.setTitle(sname + ' - MeatRight');
    if (track) {
     // Tracking Later
     this.FirebaseService.setcurrentscreenfb(sname + ' - MeatRight');
    }
  }

  setPageSEOTags(title=null,description=null,keywords=null){
    let tags=[];
    tags.push({ name: 'robots', content: 'index, follow' });
     if(!keywords){
        tags.push(  { name: 'keywords', content: 'Meatright, Aazaad Chiken, Organic Chicken , Organic Chicken Bangalore,Bangalore' } );
      }else{
       // tags.push( {name: 'keywords', content: keywords});
        this.metaService.updateTag({name: 'keywords', content: keywords});
      }
      if(!title){
        tags.push(  { name: 'title', content: 'Meatright' } );
      }else{
        this.metaService.updateTag({name: 'title', content: title});
        //tags.push( {name: 'title', content: title});
      }
    // //Buy Fresh Organic Chicken Online, Harvested after you order and delivered to your door step. These chickens are reared in the wild with access to sunlght fresh air eating naturall grass, herbs, grains and earthworms.The hens are raised on organically-maintained farms. Order Online and get Home Delivery in Bangalore.
    if(!description){
      tags.push(  { name: 'description', content: 'Buy Fresh Organic Chicken Online, Harvested after you order and delivered to your door step. These chickens are reared in the wild with access to sunlght fresh air eating naturall grass, herbs, grains and earthworms.The hens are raised on organically-maintained farms. Order Online and get Home Delivery in Bangalore.' } );
      tags.push(  { name: 'og:description', content: 'Buy Fresh Organic Chicken Online, Harvested after you order and delivered to your door step. These chickens are reared in the wild with access to sunlght fresh air eating naturall grass, herbs, grains and earthworms.The hens are raised on organically-maintained farms. Order Online and get Home Delivery in Bangalore.' } );
    }else{
      //tags.push( {name: 'description', content: description});
      this.metaService.updateTag( {name: 'description', content: description});

      //tags.push( {name: 'og:description', content: description});
      this.metaService.updateTag( {name: 'og:description', content: description});
    }

    this.metaService.addTags(tags);
  }

  removeSpecialcharacters(s) {
    if (s) {
      return s.replace(/[^A-Z0-9]+/ig, "-");
    }
    return s;
  }

  getSelectCss(center = true) {
    if (center) {
      return " .select-placeholder {padding: 2px;opacity:1!important}.select-text,.datetime-text{padding:2px;font-size: 14px!important;font-weight: bold!important; font-stretch: normal;  font-style: normal;   letter-spacing: normal;  text-align: center!important;}";

    } else {
      return " .select-placeholder {padding: 2px;opacity:1!important}.select-text,.datetime-text{padding:2px; font-size: 14px!important;font-weight: bold!important; font-stretch: normal;  font-style: normal;   letter-spacing: normal;  text-align: left!important;}";

    }
  }

  getSelectActionSheetoptions(header = 'Select', message = null) {
    let r: any = {
      header: header
    };
    if (message) {
      r.subHeader =message;
    }

    return r;
  }

  setdevice(r) {
    localStorage.setItem('firebasetoken', r.registrationId);
    localStorage.setItem('tokentype', r.registrationType);

    this.currentDevice.firebasetoken = r.registrationId;
    this.currentDevice.tokentype = r.registrationType;
  }

  shareLink(message, link) {
    // this is the complete list of currently supported params you can pass to the plugin (all optional)
    var options = {
      message: message, // not supported on some apps (Facebook, Instagram)
      url: link,
      //chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
      //appPackageName: 'com.mymameals.mainapp', // Android only, you can provide id of the App you want to share with
    };

    //console.log(options);
    var onSuccess = function (result) {
      //console.log("Share completed? " + result.completed); // On Android apps mostly return false even while it's true
      //console.log("Shared to app: " + result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
    };

    var onError = function (msg) {
      //console.log("Sharing failed with message: " + msg);
    };

    window["plugins"].socialsharing.shareWithOptions(options, onSuccess, onError);
  }

  getDevice() {
    if (!this.currentDevice.firebasetoken) {
      let r: any = {};
      r.registrationId = localStorage.getItem('firebasetoken');
      r.registrationType = localStorage.getItem('tokentype');
      this.currentDevice.firebasetoken = r.registrationId;
      this.currentDevice.tokentype = r.registrationType;
    }
    return this.currentDevice;
  }




  goBack() {
    this.location.back();
  }


  getApiUrl() {
    return this.apiUrl;
  }
  getRApiUrl() {
    return this.RapiUrl;
  }
  openURL(url) {
    const browser = this.iab.create(url, '_blank', { hidden: 'yes', hideurlbar: 'yes', hidenavigationbuttons: 'yes', location: 'no' });

    //spinner html
    var spinner = "<!DOCTYPE html><html><head><meta name='ort' content='width=device-width,height=device-height,initial-scale=1'><style>.loader {position: absolute;    margin-left: -2em;    left: 50%;    top: 50%;    margin-top: -2em;    border: 5px solid #f3f3f3;    border-radius: 50%;    border-top: 5px solid #3498db;    width: 50px;    height: 50px;    -webkit-animation: spin 1.5s linear infinite;    animation: spin 1.5s linear infinite;}@-webkit-keyframes spin {  0% { -webkit-transform: rotate(0deg); } 100% { -webkit-transform: rotate(360deg); }}@keyframes spin {  0% { transform: rotate(0deg); }  100% { transform:rotate(360deg); }}</style></head><body><div class='loader'></div></body></html>";

    //intended webpage is loaded here (facebook)
    browser.executeScript({ code: "(function() {document.write(\"" + spinner + "\");window.location.href='" + url + "';})()" });

    browser.on('loadstart').subscribe(event => {
      this.showLoader();
    });

    browser.on('loadstop').subscribe(event => {
      this.hideLoader();
      browser.show();
    });

    browser.on('loaderror').subscribe(event => {
      this.hideLoader();
    });

  }

  getAppVersionNumber() {
    return localStorage.getItem('appVersionNumber');
  }


  isDevInstance() {
    if (this.apiUrl.includes('dev')) {
      return true;
    } else {
      return false;
    }
  }

  cloneWR(p) {
    return JSON.parse(JSON.stringify(p));
  }


  showModal(id: any) {
    this.openModal(id);
  }


  openModal(event) {
    document.querySelector('#' + event).classList.add('md-show');
  }

  //    sa(m1,m2,m3){
  //      swal(m1,m2,m3);

  //}
  hideModal(event = '') {
    document.querySelector('#' + event).classList.remove('md-show');
    if (event === '0') {
      // jQuery(".modal").modal("hide");
    }
    else {
      //   jQuery("#"+id).modal("hide");

    }
  }

  alert(title, message = null) {
    this.presentAlert(title, message);
  }

  async presentAlert(header, message) {
    const alert = await this.alertCtrl.create({
      header: header,
      message: message,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            ////console.log('Confirm Okay');
          }
        }
      ]
    });

    await alert.present();
  }


  notify(type, title, msg = '', position = 'top') {
    // this.servicePNotify.notify(type, title, msg);
    this.presentToast(type, title + '\n' + msg, position);
  }

  async presentToast(type, msg, position) {
    let color = 'primary';
    //console.log(type);
    switch (type) {

      case 'info':
        color = "success";
        break;

      case 'error':
        color = 'success';
        break;

      default:
        color = 'primary';
    }
    const toast = await this.toastCtrl.create({
      message: msg,
      duration: 5000,
      color: color,
      //showCloseButton: true,
      position: position,
      buttons: [{
        text: 'close',
        icon: 'cancel',
        role: 'cancel',
        handler: () => {
          //console.log('Cancel clicked');
        }
      }
      ]

    });
    toast.present();
  }

  translate(k){
    return k;
  }
  

 

  async presentToastWithOptions() {
    const toast = await this.toastCtrl.create({
      message: 'Click to Close',
      //showCloseButton: true,
      position: 'top',
      // closeButtonText: 'Done'
    });
    toast.present();
  }

  setCurrentUser(u) {
    this.currentUser = u;
  }

  getCurrentUser() {

    if(!this.isBrowser){
      return null;
    }


    if (!this.currentUser) {
      // //console.log('setup cu');
      if (localStorage.getItem('currentUser')) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      }
    }
    return this.currentUser;
  }
  getCurrentRole() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }
  getCurrentRegion() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  safaridatefix(dd: any) {
    if (dd) {
      let subdatestr = dd.substring(0, 10);
      ////console.log(subdatestr);
      return new Date(subdatestr.replace(/-/g, "/"));
    }
  }

  field_validate($event, cf, $object) {
    let regexp: any;

    let val: any = $event.target.value;
    if (val.length < 1) { return 0; }

    switch (cf) {


      case 'mobile':
        const phoneNumberRegex = /^[0-9]+$/; // regular expression for digits
        if ( val.length > 10 || !phoneNumberRegex.test( val)) {
          // display an error message or take other actions to handle the invalid input
          $event.target.value = '';
          $object = '';
          this.notify('error', 'Please enter a valid 10 digit mobile number','','middle');

        }
       else {
        
        }
        break;

        case 'name':
          const namepattern = /^\s*[A-Za-z]+\s*(?:\s+[A-Za-z]+\s*)*\s*$/; // regular expression for digits
          if ( !namepattern.test( val)) {
            // display an error message or take other actions to handle the invalid input
            $event.target.value = '';
            $object = '';
            this.notify('error', 'Please enter only alphabets','','middle');
  
          }
         else {
          
          }
          break;

          case 'email':
            const emailpattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i; // regular expression for digits
            if ( !emailpattern.test( val)) {
              // display an error message or take other actions to handle the invalid input
              $event.target.value = '';
              $object = '';
              this.notify('error', 'Please enter valid email','','middle');
    
            }
           else {
            
            }
            break;

      case 'pincode':
        regexp =/^\d{0,6}$/;
        //console.log(regexp.test(val));
        if (regexp.test(val)) {

        } else {
          $event.target.value = '';
          $object = '';
          this.notify('error', 'Invalid Pincode Number','','top');
        }
        break;




      case 'pan':
        regexp = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        //console.log(regexp.test(val));
        if (regexp.test(val)) {

        } else {
          $event.target.value = '';
          $object = '';
          this.notify('error', 'Invalid PAN Number','','middle');
        }
        break;

      case 'gst':
        regexp = /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z1-9]){1}([a-zA-Z0-9]){1}$/;

        if (regexp.test(val)) {

        } else {
          $event.target.value = '';
          $object = '';
          this.notify('error', 'Invalid GST Number');
        }
        break;

      case 'tan':

        regexp = /^([a-zA-Z]){4}([0-9]){5}([a-zA-Z]){1}?$/;
        if (regexp.test(val)) {

        } else {
          $event.target.value = '';
          $object = '';
          this.notify('error', 'Invalid TAN Number');
        }
        break;


    }
  }


  async showLoader(content?: string) {
    if (this.loader) {
      this.loader.dismiss();
    }
    this.loader = await this.loadingCtrl.create({
      message: content ? content : "Please wait..."
    });
    this.loader.present();
    this.loader.onDidDismiss(() => {
      this.loader = null;
    });
  }
  hideLoader() {
    if (this.loader) {
      this.loader.dismiss();
    }
  }
  getGlobalProgressbarStatus() {
    return this.gpb;
  }
  showGlobalProgressbar() { this.gpb = true;
    if(this.isBrowser){
    document.getElementById('globalprogressbar').style.display = 'block';
    }
   }
  hideGlobalProgressbar() { this.gpb = false;
    if(this.isBrowser){
    document.getElementById('globalprogressbar').style.display = 'none';
    }

  }


  // check app updated version
  checkversion(appVersion) {
    return this.http
      .get<any>(this.RapiUrl + '/auth1/checkupdate?v=' + appVersion).pipe(
        map(res => res));
  }


  getfileuploadurl(file, reportProgress = false) {
    // //console.log(file);
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<any>(this.apiUrl + '/file/getfileuploadurl', formData).pipe(
      map(res => res));
  }


  setStorageObject(key: string, val: any): Promise<any> {
    return this.storage.set(key, val);
  }

  getStorageObject(key: string): Promise<string> {
    return this.storage.get(key).then((value) => {
      return value;
    });
  }

  removeStorageObject(key: string) {
    return this.storage.remove(key);
  }



  getAppIconUrl() {
    return this.apiUrl + '/appicon.png';
  }

  mysqlDate(date = null) {
    date = date || new Date();
    let isoDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();

    //    //console.log(date);
    return isoDate.split('T')[0];
  }


  randomInt(min = 1, max = 100000) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }


  latlonDistance(lat1, lon1, lat2, lon2, unit = "K") {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") { dist = dist * 1.609344 }
      if (unit == "N") { dist = dist * 0.8684 }
      return dist;
    }

  }


  base64ToFile(event: any) {
    //return base64ToFile(event.base64);
  }


  /*cart methods*/
  cart_fetch() {
 
    return this.cloneWR(this.cart);
  }


  cart_add(item: any) {
    if (!this.cart) {
      this.cart.items = [];
    }

    if(item.is_harvest){
      this.notify('error', "Harvest Product Not Allowed");
      return;
    }
    //finalpricefix
    if (!item.finalprice) {
      item.finalprice = item.price;
    }

    //assigning discount price
    if (item.dstprice) {
      item.finalprice = item.dstprice;
    }

    //check if item exist in array
    if (!this.cart.items) {
      this.cart.items = [];
    }

    //let checkitem=this.cart.items.filter(citem=>citem.id==item.id);
    let checkitem = this.cart.items.filter(citem => {
      if (citem._id == item._id && citem.batchno == item.batchno && citem.harvest_date == item.harvest_date) {
        return citem;
      }

    });
    if (!checkitem[0]) {
      //console.log(checkitem);
      if (!item.quantity) {
        item.quantity = 1;
      }


      this.cart.items.push(item);
      //this.notify('info','Item Added to Cart');
    } else {
      //console.log('false');

      //delete old item
      let fi = this.cart.items.findIndex(citem => citem._id == item._id);


      this.cart.items[fi].quantity = this.cart.items[fi].quantity + 1;
      //this.cart.items.splice(fi,1);
      //this.cart.items.push(item);
      this.notify('info', 'Item updated in Cart');


      //_paq.push(['removeEcommerceItem',item.id]);
    }





    _paq.push(['addEcommerceItem',
      item._id.toString(), // (Required) productSKU
      item.name, // (Optional) productName
      [item.species_name], // (Optional) productCategory
      item.finalprice, // (Recommended) price
      item.quantity // (Optional, defaults to 1) quantity
    ]);

    _paq.push(['trackEcommerceCartUpdate', this.cart.total]);
    this.FirebaseService.logbfevent('add_to_cart', item);
    //crosscheck with localStorage
    //this.cart.user = this.currentUser._id;
    this.cart['currentUser'] = this.getCurrentUser();
   
    let usermobile = 'guest';
    if(this.getCurrentUser()){
      usermobile = this.getCurrentUser().mobile 
    }
    console.log(this.cart);
    localStorage.setItem(usermobile+'cart', JSON.stringify(this.cart));

  }

  cart_remove(item) {

  }
  cart_clear() {
    if (this.cart.user_email) {
      this.tempemail = this.cloneWR(this.cart.user_email);
    }

    localStorage.removeItem('cart');
    this.cart = this.cloneWR({});
    this.cart.user_email = this.tempemail;
    let usermobile = 'guest';
        if(this.getCurrentUser()){
          usermobile = this.getCurrentUser().mobile 
        }
       localStorage.removeItem(usermobile+'cart');
    //console.log(this.cart.user_email);
    //console.log(this.tempemail);
  }

  cart_update(index = -1) {
    //this.cart.user = this.currentUser._id;
    this.cart['currentUser'] = this.getCurrentUser();
    let usermobile = 'guest';
    if(this.getCurrentUser()){
      usermobile = this.getCurrentUser().mobile 
    }
    localStorage.setItem(usermobile+'cart', JSON.stringify(this.cart));
  }

  cart_isincart(item) {
    if(!this.cart){
      this.cart = { items: []};
    }
  //console.log(item);
    if (this.cart.items) {
      let checkitem = this.cart.items.filter(citem => {
        if (citem._id == item._id && citem.batchno == item.batchno && citem.harvest_date == item.harvest_date) {
          return citem;
        }

      });
      if (checkitem[0]) {
        ////console.log('true');
        return true;
      }
    }
    ////console.log('false');

    return false;
  }
  cart_total() {
    let t = 0;
    let q = 0;
    if(!this.cart.items){
      this.cart.items = [];
    }
    ////console.log(this.cart);
    this.cart.items.forEach(element => {
      t = t + element.quantity * element.finalprice;
      q = q + element.quantity;
    });
    this.cart.total = t;
    this.cart.quantity = q;
    //totalbill
    if (t >= this.mincartvalueforfreedelivery) {
      this.cart.delivery_charge = 0;
      //    this.cart.delivery_gst=0;
    } else {
      this.cart.delivery_charge = this.delivery_charge;
      //    this.cart.delivery_gst=this.delivery_charge*18/100;
    }

    if (this.cart.coupon_code) {
      this.cart.offer_code = null;

      let cc = this.cart.coupon_code;
      for (var i = 0; i < this.cart.coupon_code.length; i++) {
        console.log(this.cart.coupon_code[i]);
        if (this.cart.finaltotal <= this.cart.coupon_code[i].minimum_order_value) {
          //console.log('final total is less then minimum order value' + this.cart.coupon_code[i].minimum_order_value);
          this.notify('error', "To Apply Coupon Code Minimum Order Value Should be " + this.cart.coupon_code[i].minimum_order_value);
          this.cart.coupon_code = null;
          this.cart.finaltotal = t;

          return 0;
        }
        //console.log(this.cart);
        let discount = this.cart.total * (this.cart.coupon_code[i].discount / 100);
        this.cctotal = this.cart.total - Math.round(discount);

        
        // console.log(discount);
       // console.log(Number(discount.toFixed()));
        let roundDiscount: number = Number(discount.toFixed());
        //console.log(this.cart.coupon_code[i].max_discount_value);
        if (roundDiscount >= this.cart.coupon_code[i].max_discount_value) {
          t = t - this.cart.coupon_code[i].max_discount_value;
          this.cart.coupon_code[i].discount_value = this.cart.coupon_code[i].max_discount_value;
        } else {
          t = t - roundDiscount;
          this.cart.coupon_code[i].discount_value = roundDiscount;

        }
        this.cart.finaltotal = t;
        //console.log(t);
      }

    }
    if (this.cart.offer_code) {
      this.cart.coupon_code = null;

      let cc = this.cart.offer_code;
      for (var i = 0; i < this.cart.offer_code.length; i++) {
        ////console.log(this.cart.offer_code[i]);
        if (this.cart.finaltotal <= this.cart.offer_code[i].minimum_order_value) {
          ////console.log('final total is less then minimum order value'+this.cart.offer_code[i].minimum_order_value);
          this.notify('error', "To Apply Offer Code Minimum Order Value Should be " + this.cart.offer_code[i].minimum_order_value);
          this.cart.offer_code = null;
          this.cart.finaltotal = t;
          return 0;
        }
        ////console.log(this.cart.offer_code[i].final_price);
        let discount = this.cart.offer_code[i].final_price * (this.cart.offer_code[i].discount / 100);
        ////console.log(discount);
        ////console.log(Number(discount.toFixed()));
        let roundDiscount: number = Number(discount.toFixed());
        ////console.log(this.cart.offer_code[i].max_discount_value);
        if (roundDiscount >= this.cart.offer_code[i].max_discount_value) {
          t = t - this.cart.offer_code[i].max_discount_value;
          this.cart.offer_code[i].discount_value = this.cart.offer_code[i].max_discount_value;
        } else {
          t = t - roundDiscount;
          this.cart.offer_code[i].discount_value = roundDiscount;

        }
        this.cart.finaltotal = t;
        // //console.log(t);
      }

    }


   
    //parseInt(this.cart.delivery_gst)
    t = t + parseInt(this.cart.delivery_charge);

    if(this.is_express_delivery){
      t = t + 99;
    }



    this.cart.finaltotal = t;


    return t;
  }

  cart_count() {
    //  //console.log('crosscheck hittings');
    //fix with localStorage
    /*if (this.cart) {
      if (this.cart.items) {
        return this.cart.items.length;
      }

    }
    return 0;
    */
      if(!this.cart){
        let usermobile = 'guest';
        if(this.getCurrentUser()){
          usermobile = this.getCurrentUser().mobile 
        }
        let lcart=localStorage.getItem(usermobile+'cart');
        if(lcart){
          if(lcart.length>10){
          this.cart=JSON.parse(lcart);
          }
        }
      }
      //console.log(this.cart);
      if(this.cart){
        
          return this.cart.items?this.cart.items.length:0;
        
     }
     return 0;
  }


}
