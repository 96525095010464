
import { throwError as observableThrowError, Observable } from 'rxjs';

import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from './common.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';



 

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public commonService: CommonService, private router: Router) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   

    if(this.commonService.isBrowser){
      this.commonService.showGlobalProgressbar();
    if (!window.navigator.onLine) {
      this.commonService.notify('error', 'Oops!', 'No Internet Connection Detected');
      this.commonService.hideGlobalProgressbar();
    }
  }

    // add authorization header with jwt token if available
    //let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    let currentUser = this.commonService.getCurrentUser();
    if (currentUser && currentUser.token) {
      request = request.clone({
        setHeaders: {
          AuthToken: `${currentUser.token}`
        }
      });
    }

    return next
      .handle(request).pipe(
        tap((ev: HttpEvent<any>) => {
          //
          if (ev instanceof HttpResponse) {
            // //console.log('repsonse');
            if(this.commonService.isBrowser){
            this.commonService.hideGlobalProgressbar();
            }

          }
          // 
        }),
        catchError(response => {
          if(this.commonService.isBrowser){
          this.commonService.hideGlobalProgressbar();
          }
          if (response instanceof HttpErrorResponse) {
            //console.log('Processing http error', response);
            if (response.error) {
              console.log(response.error);
              //hide loader
              if(this.commonService.isBrowser){
                this.commonService.hideLoader();
                this.commonService.hideGlobalProgressbar();
              }
              let emsg = "Error Occured";
              if (response.error.message) {
                emsg = response.error.message.toString().replace('Bad Request: ', '');
              }else{
                emsg = response.error.toString().replace('Bad Request: ', '');
              }

              //i dont want to show error message if error is progressevenet  or jwt expired
              if(emsg == 'jwt expired'){

              }else{
                this.commonService.notify('error', 'Oops!', emsg, 'middle');
              }
              if (response.status === 401) {

                if(this.commonService.isBrowser){
                if (localStorage.getItem('token')) {
                  localStorage.removeItem('currentUser');
                  localStorage.removeItem('token');
                  this.commonService.currentUser = null;
                  this.commonService.apiToken = null;
                  // this.router.navigate(['/auth/login']);
                }
              
                this.router.navigate(['/auth/login']);
              }
              }
            } else {
              this.commonService.notify('error', 'Oops!', 'Error Occured');
            }
          }
          return observableThrowError(response);
        }));
  }
}
