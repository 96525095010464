import { Component, Input, Output, OnInit, NgZone } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { AlertController } from '@ionic/angular';

import { InAppBrowser, InAppBrowserEvent } from '@ionic-native/in-app-browser/ngx';

import { CommonService, UserService, AuthService, BackendService, FirebaseService,ApiService, HelperService } from '../../../providers/index';

declare const fbq;
declare const _paq;


@Component({
  selector: 'app-ordercard-single',
  templateUrl: './ordercard-single.html',
  styleUrls: ['./ordercard-single.scss'],
})

export class OrdercardsingleComponent implements OnInit {

  @Input() options: any;
  item: any = {};
  order: any = {};
  searchspecies_id = 0;
  public isMenuOpen: boolean = false;



  iabloaded = false;
  browser: any;
  interval2: any;
  bwindow: any;
  initpayment = false;
  appsettings: any;
  constructor(
    public alertCtrl: AlertController,
    public router: Router,
    public route: ActivatedRoute,
    public commonService: CommonService,
    public firebaseService: FirebaseService,
    private iab: InAppBrowser,
    public apiService: ApiService,
    private zone: NgZone,
    private helperService: HelperService
    ) {

  }

  ngOnInit(): void {
    // //console.log(this.options);
    // this.items = ['nikhil', 'ganesh'];
    if (this.options) {
      //console.log(this.options);
      this.item = this.options.item;
      this.order = this.options.order;
      this.order.debugjson = JSON.parse(this.order.debugjson);

      if (this.options.initpayment) {
        this.initpayment = true;
        if (!this.order.payment_refno) {
          this.payWithPayu(true);
        }


      }

      // if(this.order.payment_refno){
      // this.logpurchaseevent();
      // }

      //TEST PURCHASE EVENT else{
      // let bforder:any=this.order;
      // bforder.value=this.order.total;
      // bforder.transaction_id=this.order.id;
      // this.commonService.FirebaseService.logbfevent('purchase',bforder);
      // }

    }
    this.appsettings = JSON.parse(localStorage.getItem('appsettings'));
  }

  ionViewWillLeave() {
    //console.log('stopping ping');
    if (this.interval2) {
      clearInterval(this.interval2);
    }
  }

  reorder() {
    //        //console.log
    //this.commonService.cart = this.order.debugjson.cart;
    console.log(this.appsettings);
    this.order.debugjson.cart.items.filter(c=>{
     this.checkisinstock(c);
     
    });
   
    this.commonService.cart_update();
    //console.log("checkkkk", this.commonService.cart);
    this.commonService.cart.deliverydate = null;
    this.commonService.cart.coupon_code = null;
    this.commonService.cart.offer_code = null;
    this.commonService.cart.address_id =null;
    this.commonService.cart.payment_mode =null;
    this.commonService.cart.is_pod =null;
    this.router.navigateByUrl('/checkout');
  }

  async checkisinstock(item){
    let tempproduct = await this.appsettings.products.filter(p=> p._id == item._id);
    if(tempproduct.length>0){
      if(tempproduct[0].is_stock){     
      
        if(tempproduct[0].price != item.price){
          this.helperService.presentToast('error','Price of '+item.name +' is updated to '+tempproduct[0].price);
          item.price = tempproduct[0].price;
        }
        tempproduct[0]['quantity'] = item.quantity;
        item.finalprice = item.price;
        let canshowpricing = true;

        if (item.is_customizable && item.productcustomization_data) {
          item.productcustomization_data.forEach(element => {
            if (element.type == 'radio') {
              if (element.value) {
                item.finalprice = parseInt(item.finalprice) + parseInt(element.value.price);
                console.log(item);
                canshowpricing = true;
              } else {
                canshowpricing = false;
              }
            }
    
            if (element.type == 'toggle') {
              if (element.value == true) {
                item.finalprice = parseInt(item.finalprice) + parseInt(element.price);
    
              }
            }
    
    
          });
        }

        this.commonService.cart_add(item);
        return true;
      }else{
        this.helperService.presentToast('error',item.name +' is out of stock');
        return false 
      }
    }else{
      return false;
    }
  }


  payWithPayu(trackevent = false) {

    if (this.interval2) {
      clearInterval(this.interval2);
    }

    if (this.order.payment_refno) {
      this.commonService.notify('Please Wait', 'We are processing your order');
      return 0;
    }

    //console.log(this.order);
    //console.log(this.commonService.tmppod);

    if (this.order.is_pod == 1 && this.commonService.tmppod == 0) {
      this.logpurchaseevent();
      //console.log('payment on delivery');
      this.commonService.tmppod = 1;
      if (trackevent) {
        _paq.push(['trackEvent', 'Payment', 'Cash on Delivery', 'Success', this.order._id]);
      }

      return 0;
    }


    _paq.push(['trackEvent', 'Payment', 'Online', 'Initiated', this.order._id]);


    let pgurl = this.commonService.apiUrl + '/payu/pg/?order_id=' + this.order._id;
    //check if onnative app 
    if (!localStorage.getItem('onesignal')) {
      //console.log('opening in new tab non app');
      // this.commonService.notify('info', 'Redirecting to Payment Gateway', 'Please Allow Popup to open ');
      this.commonService.notify('info','Transaction canceled');
      //this.bwindow = window.open(pgurl, '_blank');
      this.browser = this.iab.create(pgurl,'_blank','location=yes,hideurlbar=yes');
      this.iabloaded = true;
      this.browser.on('beforeload').subscribe((event: InAppBrowserEvent) => {
        console.log('Loading URL:', event);
        if (event.url.startsWith('http://upi')) {

          //this.browser.load
          // Change the URL to the new URL
          //window.open(event.url, '_system');

        }
      });
      
      //this.bwindow.focus();
    } else {
      //console.log('opening   in the app');

      this.browser = this.iab.create(pgurl, '_blank');

      try {
        this.browser.on('exit').subscribe(event => {
          //    browser.insertCSS({ code: "body{color: red;" });
          //  this.commonService.notify('info','Waiting for Payment');
          //  this.refreshToken();

          if (!this.order.payment_refno) {
            //this.showpaymentalertmessage=true;
          }

        });
      } catch (error) {
        //console.log(error);
      }

     
    }

    this.commonService.activecheckingorderid = this.order._id;
    this.interval2 = setInterval(() => {
      this.checkOrderStatus();
    }, 4000);

  }


  checkOrderStatus() {
    //disble pinginterbal if check fix
    if (this.commonService.activecheckingorderid != this.order._id) {
      if (this.interval2) {
        clearInterval(this.interval2);
      }
    }
    let params: any = {};
    // params.type=type;

    this.apiService.callapi(this.apiService.APIS.ORDER_CHECKORDERSTATUS, { id: this.order._id })
      .subscribe(
        data => {
          if (data) {
            data.debugjson = JSON.parse(data.debugjson);
            if (data.payment_refno) {
              this.order = data;
              this.order.payment_refno = data.payment_refno;
              if (this.iabloaded) {
                this.browser.close();
                this.iabloaded = false;
                
              }
              if (this.bwindow) {
                //console.log('calling close');
                this.bwindow.close();
              }
              this.commonService.notify('info', 'Payment Successfull');

              _paq.push(['trackEvent', 'Payment', 'Online', 'Success', this.order._id]);

              this.logpurchaseevent();

              //this.clearIntervalping();
              if (this.interval2) {
                clearInterval(this.interval2);
              }
            }else if(data.payment_status == 'failure'){
              this.order = data;
              this.order.is_cancelled = data.is_cancelled;
              if (this.iabloaded) {
                this.browser.close();
                this.iabloaded = false;
                
              }
              if (this.bwindow) {
                //console.log('calling close');
                this.bwindow.close();
              }
              this.commonService.notify('info', 'Payment Failed');

              _paq.push(['trackEvent', 'Payment', 'Online', 'Failure', this.order._id]);
              this.logpurchaseevent();

              //this.clearIntervalping();
              if (this.interval2) {
                clearInterval(this.interval2);
              }
            }
             else {
              //disable ping if 
              //this.checkOrderStatus();

            }
          }
        },
        error => {

          // this.loading = false;

        });
  }


  async cancel_order_prompt() {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Are you sure to Cancel Order #' + this.order._id,
      message: 'Please refer FAQs for Cancellation , returns and refund Policies.',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Yes - Confirm Canellation',
          handler: () => {
            this.cancel_order();
            //console.log('Confirm Okay');
          }
        }
      ]
    });

    await alert.present();
  }


  cancel_order() {
    let model: any = {};
    model._id = this.order._id;
    model.is_cancelled = 1;
    this.apiService.callapi(this.apiService.APIS.ORDER_CANCEL, { _id: this.order._id, is_cancelled: 1 })
      .subscribe(
        data => {
          this.zone.run(()=>{
            this.order.is_cancelled = 1;
            //this.order.delivery_status = 'cancelled';
          })
          this.commonService.notify('info', 'Order Cancelled');
        });

  }

  logpurchaseevent() {
    let bforder: any = {};
    bforder.value = this.order.finaltotal;
    bforder.revenue = this.order.finaltotal;
    bforder.transaction_id = this.order._id;
    bforder.currency = "INR";
    //console.log('logging purchase event', bforder);

    //console.log('analyticsdata', this.order);

    _paq.push(['trackEcommerceOrder',
      this.order._id, // (Required) orderId
      this.order.finaltotal, // (Required) revenue
      this.order.finaltotal, // (Optional) subTotal
      0, // 1.5, // (optional) tax
      this.order.delivery_charge // 1, // (optional) shipping
      //false // (optional) discount
    ]);
    setTimeout(() => {


      //log payment success event
      /*if(this.order.payment_mode=='online'){
      _paq.push(['trackEvent', 'Payment', 'Online','Success']);}
      else    if(this.order.payment_mode=='cash on delivery'){
      _paq.push(['trackEvent', 'Payment', 'Cash on Delivery','Success']);
      }*/

      this.firebaseService.logbfevent('purchase', bforder);
      fbq('track', 'Purchase', bforder);

      this.commonService.cart_clear();
      _paq.push(['clearEcommerceCart']);


      //reassign session to register user in case of guest
      let cu = this.commonService.getCurrentUser();
      if (!cu) {
        //console.log('assigning session');
        //assign session to user in case of guest user
        let u: any = {};
        u.name = this.order.user_name;
        u._id = this.order.user;
        this.firebaseService.setfbuserid(u);
      }



    }, 1000);


  }

  async deliverycostInfo() {
    const alert = await this.alertCtrl.create({
      header: 'Delivery Cost Information',
      message: '',
      cssClass: 'delivery-cost-info',
      buttons: [
        {
          text: 'Ok',
          handler: () => {
          }
        }
      ]
    });

    alert.present();
  }
  
  getFormattedAddress(address){
    let pincode = address.pincode;
    let formatted = '';
    if(!address.formatted_address.includes(pincode)) {
      let ss = address.formatted_address.split(',');
      if(ss[ss.length-2]){
        let ss1 = ss[ss.length-2].split(' ');
        if(pincode != ss1[ss1.length-1]){
          formatted ='Custome Pincode '+ pincode;
        }
      }
    }
    return formatted;
  }
}