import { Component, Input, Output, OnInit, ViewChild, ViewChildren, ElementRef  } from '@angular/core';

import { IonSlides, NavController } from '@ionic/angular';


import { ActivatedRoute } from '@angular/router';

import { CommonService } from 'src/app/providers';


@Component({
  selector: 'app-desktopfooter',
  templateUrl: './desktopfooter.html',
  styleUrls: ['./desktopfooter.scss'],
})

export class DesktopfooterComponent {
  
  sliderOpts = {
   slidesPerView: this.checkScreen(),
   spaceBetween: 5,
  initialSlide: 3,
  preloadImages: true,
   leftSlides: true,
   rotate: 50,
   autoplay: {
    delay:2000,
      disableOnInteraction: false,
   },
   speed: 1000, //0.3s transition
  duration: 5000,
    pagination: {
     el: ".swiper-pagination",
    type: "bullets",
     clickable: true,
  //   loop: true,
  //  pauseOnMouseEnter: true,
  //  pauseOnHover: true
   }
  };

  //  SlideOpts= {
  //   initialSlide: 1,
  //   spaceBetween: 5,
  //   speed: 4000,
  //   duration: 4000,
  //   leftSlides: true,
  //      rotate: 50,
  //   autoplay: {
  //     delay: 2000,
  //     disableOnInteraction: false,
  //   },
  //   pagination: {
  //       el: ".swiper-pagination",
  //        type: "bullets",
  //        clickable: true,
  //   },
  //   loop: true,
  //   slidesPerView: 1,
  //   pauseOnHover: true // set pauseOnHover to true
  // };
 

 

  
   

  












  // @ViewChild('slider', { static: true }) private slider: IonSlides;

  // public slideOpts = {
  //   initialSlide: 1,
  //   speed: 400
  // };

  // @ViewChild('slider', { read: ElementRef }) slider: ElementRef;

  // ngAfterViewInit() {
  //   const sliderEl = this.slider.nativeElement;
  //   sliderEl.addEventListener('mouseenter', () => {
  //     sliderEl.swiper.autoplay.stop();
  //   });
  //   sliderEl.addEventListener('mouseleave', () => {
  //     sliderEl.swiper.autoplay.start();
  //   });
  // }

// change = src => {
//     document.getElementById('main').src = src
// }

  constructor(public commonService:CommonService) {

  }
  // @ViewChild('slides', { static: true }) slides: IonSlides;
  

  // imageUrl: string[] = [
  
  //  '/assets/img/unnamed.jpg',
  //   '/assets/img/unnamed.jpg',
  //  '/assets/img/unnamed.jpg'
  //  ];
  


  
  











  
  
//  swiper = new Swiper('.swiper', {
//     zoom: {
//       maxRatio: 5,
//     },
//   });




//  @ViewChild('slider') slider: ElementRef;
//  ngAfterViewInit() {
//  const swiper = new Swiper(this.slider.nativeElement, {
//     zoom: true,
//    });
// }







//   public async ionSlideDidChange(): Promise<void> {
//     const index = await this.slider.getActiveIndex();

// }
/* Without setting height the slides will take up the height of the slide's content */

checkScreen() {
  let innerWidth = window.innerWidth;
  switch (true) {
    case 340 <= innerWidth && innerWidth <= 400:
      return 1;
    case 401 <= innerWidth && innerWidth <= 700:
      return 1;
    case 701 <= innerWidth && innerWidth <= 900:
      return 1;
    case 901 <= innerWidth:
      return 1;
  }
}
   
}
