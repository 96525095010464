import { Component,PLATFORM_ID, Inject, OnInit, ElementRef, OnDestroy  } from '@angular/core';
import { isPlatformServer,isPlatformBrowser, ViewportScroller } from '@angular/common';
import { Platform, ModalController } from '@ionic/angular';
import { Route, Router,ActivatedRoute, NavigationEnd } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { AlertController } from '@ionic/angular';
import OneSignal from 'onesignal-cordova-plugin';
import { NavController } from '@ionic/angular';
import { InAppBrowser, InAppBrowserEvent } from '@ionic-native/in-app-browser/ngx';

import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';


import {
  FirebaseService,
  CommonService,
  BackendService,
  AuthService,HelperService,ApiService
} from './providers/index';
import { NetworkService } from './providers/network.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
  
  public selectedIndex = 0;
  loggedIn = true;
  currentUser: any;
  sappversion = '-1';
  menuitems: any;

  //fix for duplicate clicks
  canprocessnotifications = true;
  appmode = 'customer';
  currentFilter: any = {};
  data: any;
  q: any;
  art = false;
  private lastBack = Date.now();
  viewportScroller: any;
  browser: any;
  networkStatus: string;
  connectivitySubscription: Subscription;
  slowConnectionTimeout: number = 10000; // 10 seconds
  isDisconnected: boolean = false;
  searchTerm: string = '';
  searchBarVisible: boolean = false;
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    public platform: Platform,
    public helperService: HelperService,
    public apiService: ApiService,
    public commonService: CommonService,
    public authService: AuthService,
    public backendService: BackendService,
    private navController: NavController,
    
    private bnIdle: BnNgIdleService,
    public alertCtrl: AlertController,
    public splashScreen: SplashScreen,
    private router: Router, private route: ActivatedRoute, // for id navigate of desktop footer happy cuatomer
    public elementRef: ElementRef,
    public ViewportScroller: ViewportScroller,
    public iab: InAppBrowser,
    private networkService: NetworkService,
  ) {
    // this.bnIdle.startWatching(120).subscribe((res) => {
    //   if(res) {
    //   let msg = 'you are idle for only 2 min';
    //   this.popupmsg(msg);
    //   }
    // });
 
   //console.log('isBrowser',this.helperService.isBrowser);

  this.initializeApp();
  this.helperService.isBrowser = isPlatformBrowser(platformId);
  this.commonService.isBrowser = isPlatformBrowser(platformId);
  
  this.platform.backButton.subscribe(() => {
    this.navController.back();
  });

  }


   ngOnInit() {
    this.getAreacodes();
    // this.route.fragment.subscribe(fragment => {
    //   const element = document.querySelector("#" + fragment);// for id navigation of desktop footer  happy customer
    //   if (element) {
    //     element.scrollIntoView();
    //   }
    // });
    this.checkInternetConnectivity();
   }

   checkInternetConnectivity() {
    this.networkService.startCheckingConnectivity(this.slowConnectionTimeout);
    this.connectivitySubscription = this.networkService.isConnected.subscribe(isConnected => {
      if (isConnected && this.isDisconnected) {
        this.commonService.notify('info', 'We are Back Online');
      } 
      if(!isConnected) {
        this.isDisconnected = true;
        this.commonService.notify('primary', 'Could Not Connect to Internet');
      }
      this.networkStatus = isConnected ? 'Connected' : 'Disconnected';
    });

    //this.checkSlowConnectivity();
   }
 
  ngOnDestroy() {
    this.connectivitySubscription.unsubscribe();
  }


   goToSection() {
    this.router.navigateByUrl('').then(() => {
     
     setTimeout(() => {
         const element = document.querySelector(`#${'happy_customer'}`);
         if (element) {
         element.scrollIntoView({ behavior: 'smooth' });
        
        }
      },1000);
    });
  
   
  }

  

   getAreacodes(){
    this.apiService.callapi(this.apiService.APIS.AREACODE_LIST,{pageoffset:0, limit: 1000}).subscribe(res => {
      this.commonService.areacodes = res;
      // console.log('areacodes');
      // console.log(this.commonService.areacodes);
    });
  }
    async popupmsg(msg) {
    const alert = await this.alertCtrl.create({
      header: 'Note!',
      message: msg,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
          }
        }
      ]
    });

    alert.present();
  }

  

  initializeApp() {
    this.platform.ready().then(() => {
      this.splashScreen.hide();
      if(this.helperService.isBrowser){
      if (window.screen.availWidth > 800) {
        this.commonService.isDesktop = true;
      } else {
        this.commonService.isDesktop = false;
      }
    }else{
      this.commonService.isDesktop = true;
    }
      //console.log('isDesktop', this.commonService.isDesktop);
      if (window['plugins']) {
        this.OneSignalInit();
      }

      window.addEventListener('customurlscheme', (event: any) => {
        const path = event.detail.path;

        // Handle deep link
        if (path === '/orders') {
          // Call API to get payment result using transaction ID
          this.router.navigate(['/orders']);
        }
      });
    });

    this.platform.backButton.subscribe(() => {
      if (Date.now() - this.lastBack < 500) { // logic for double tap: delay of 500ms between two clicks of back button
        navigator['app'].exitApp();
      }
      this.lastBack= Date.now();
  });

  }

  OneSignalInit(): void {
    // Uncomment to set OneSignal device logging to VERBOSE  
    // OneSignal.setLogLevel(6, 0);
  
    // NOTE: Update the setAppId value below with your OneSignal AppId.
    window['plugins'].OneSignal.setAppId("b25ae2a9-ef23-4a9c-a184-fc8e0aee6f13");
    window['plugins'].OneSignal.setNotificationOpenedHandler(function(jsonData) {
        // console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
    });
  
    // Prompts the user for notification permissions.
    //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
    window['plugins'].OneSignal.promptForPushNotificationsWithUserResponse(function(accepted) {
        // console.log("User accepted notifications: " + accepted);
    });
  }

  getHomeLink() {
    return this.currentUser?.active == '1' ? '/account/profile' : '/login';
    /*let link='/home/dashboard';
    if(this.currentUser){
      link='/home/dashboard';
    }
    return link;
    */
  }

  


  menuOpened() {
    //console.log('menuOpened in APPCom');
    this.currentUser = this.commonService.getCurrentUser();
    ////console.log(this.currentUser);
    if (this.currentUser) {
      if (this.currentUser._id) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false;
      }
    } else {
      this.loggedIn = false;
    }
    //if(this.otherService.globalappdata.appsettings.menuitems){
    //this.menuitems=this.otherService.globalappdata.appsettings.menuitems;
    // }
  }

 async logout() {
  console.log('hello');



  const alert = await this.alertCtrl.create({
    cssClass: 'my-custom-class',
    header: 'Do you want Logout ?',
    buttons: [
      {
        text: 'No',
        role: 'cancel',
        cssClass: 'secondary',
        handler: (blah) => {
          //console.log('Confirm Cancel: blah');
        }
      }, {
        text: 'Yes',
        handler: () => { 
          this.commonService.notify('info', 'Logged Out !');
          this.currentUser = null;
          this.commonService.cart.address_id = null;
          this.router.navigateByUrl('/login');

          this.authService.logout().then(() => {
            if (this.platform.is('cordova')) {
              console.log('unsubscribing from notifications');
              window["plugins"].OneSignal.removeExternalUserId();
            }
          });
          return;
        }
      }
    ]
  });

  await alert.present();
}


  locationalert() {
    //alert('Dear user ,As of Now - MeatRight Services are available within Bangaluru City Only.');
    // let am='Dear Customer, thank you for visiting our site. We are currently operating in Bangalore only. You may read more about our Aazaad country chicken. ';
    let am = 'We currently only operate in and around the Bangalore metro area, however we do plan to expand across India in the coming months. If you’d like us to come to your city, drop us an email at <a href="mailto:hi@meatright.in">hi@meatright.in</a> and we may give it a thought!'
    this.commonService.alert('MeatRight', am);
  }


  offers() {
    this.apiService.callapi(this.apiService.APIS.ADMIN_OFFERCODELIST, this.currentFilter).subscribe(res => {
      //console.log("list", res);
      this.data = res.offers;
      //console.log("list", this.data);
      // this.q=this.data.species;
      //console.log("artlist", this.q);
      this.art = true;
    }, error => {

    });
  }
  closemodal() {
    this.art = false;
  }

  opensocial(url) {
    this.browser = this.iab.create(url,'_system');

    window.open(url, '_system');
  }
 

  toggleSearchBar() {
    this.searchBarVisible = !this.searchBarVisible;
  }

  closeSearchBar() {
    this.searchBarVisible = false;
    this.searchTerm = '';
  }
  onSearchChange(event: any) {
    const enteredPincode = event.detail.value;
    if (enteredPincode.length === 6) {
      this.getPincode();
      this.searchTerm=null;
    }
  }

  getPincode() {
    if (!this.searchTerm || this.searchTerm.trim() === '') {
      this.commonService.notify('info', '', 'Please enter a pincode', 'top');
      return;
  }
    console.log(this.searchTerm);
    this.searchBarVisible = false;
  
    this.apiService.callapi(this.apiService.APIS.AREACODE_LIST, { pincode: this.searchTerm }).subscribe(res => {
      console.log(res);
  
      if (res.length == 0) {
        this.commonService.notify('info', '', 'We do not deliver in this location', 'top');
      } else { 
        this.commonService.notify('info', '', `We deliver in this location and delivery charge is ₹${this.commonService.cart.delivery_charge = res[0]['category']['price']}`, 'top');
      }
    });
    this.searchTerm=null;
  }
  closesearch(){
    this.searchBarVisible = false;
  }

}
