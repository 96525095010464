import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Observable, BehaviorSubject, timer, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class NetworkService {
  private isConnectedSubject: BehaviorSubject<boolean>;

  constructor(private network: Network) {
    this.isConnectedSubject = new BehaviorSubject<boolean>(this.checkNetworkStatus());
  }

  startCheckingConnectivity(timeoutDuration: number = 5000): void {
    this.network.onDisconnect().subscribe(() => {
      this.isConnectedSubject.next(false);
    });

    this.network.onConnect().subscribe(() => {
      this.isConnectedSubject.next(true);
    });

    this.isConnectedSubject
      .pipe(
        switchMap((isConnected: boolean) =>
          isConnected ? timer(timeoutDuration) : throwError('No internet connection')
        ),
        catchError(() => {
          this.isConnectedSubject.next(false);
          return throwError('No internet connection');
        })
      )
      .subscribe();
  }

  get isConnected(): Observable<boolean> {
    return this.isConnectedSubject.asObservable();
  }

  private checkNetworkStatus(): boolean {
    return this.network.type !== 'none';
  }
}
