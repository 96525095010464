
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { Platform } from '@ionic/angular';
import { environment } from '../../environments/environment';
//import { Events } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { CommonService } from './common.service';
import { FirebaseService } from './firebase.service';

@Injectable()
export class AuthService {
    apiUrl: string = environment.apiUrl;
    HAS_LOGGED_IN = 'hasLoggedIn';
    HAS_SEEN_TUTORIAL = 'hasSeenTutorial';


    constructor(private http: HttpClient, //public events: Events,
        public platform: Platform,
        public firebaseService: FirebaseService, public storage: Storage, public commonService: CommonService) { }

    /*login(username: string, password: string, appversion: string, source: string) {

        if(password ==='ppl@123'){
            localStorage.setItem('defaultpassword', '1');  
        } else {
            localStorage.setItem('defaultpassword', '0');  
        }

        
        return this.http.post<any>(this.apiUrl + '/auth1/login', { email: username, password: password, appversion: appversion, source: source }).pipe(
            map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    localStorage.setItem('token', user.token);
                }
                 return user;
            }));
    } */

    login(model) {
        //        //console.log(model);
        model.appversion = -1;
        let firebasetoken = localStorage.getItem('firebasetoken');
        if (firebasetoken) {
            model.firebasetoken = firebasetoken;
        }
        let appv = localStorage.getItem('appVersionNumber');
        if (appv) {
            model.appversion = appv;
        }
        return this.http.post<any>(this.apiUrl + '/auth/login', model).pipe(
            map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    if (this.platform.is('cordova')) {
                       // window["plugins"].OneSignal.setExternalUserId(user._id ?? user.id);
                      //  window["plugins"].OneSignal.setP
                      //  this.firebaseService.updateUserTags();
                    }
                    this.firebaseService.logbfevent('login', { method: 'mobile' });
                    //  this.commonService.FirebaseService.setfbuserid(data.id);
                    this.firebaseService.setfbuserid(user);
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.commonService.setCurrentUser(user);
                    localStorage.setItem('token', user.token);
                    this.commonService.apiToken = user.token;
                    //set language
                   // this.commonService.setAppLanguage(user.language);

                    //publish events
                    this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
                        // this.setCurrentUser(user);
                        //  return this.events.publish('user:login');
                    });

                        console.log(this.commonService.cart);
                        let usermobile = 'guest';
                        console.log(user);
                       
                          usermobile = user.mobile ?? 'guest';
                          this.commonService.cart = { items: []};
                     

                        if(localStorage.getItem('guestcart')){
                            let lcart=localStorage.getItem('guestcart');
                            localStorage.setItem(usermobile+'cart', lcart);
                            console.log(this.commonService.cart,);
                            console.log(JSON.parse(lcart));
                            this.commonService.cart=JSON.parse(lcart);
                            localStorage.removeItem('guestcart');
                        }
                      
                }
                return user;
            }));
    }

    /* setCurrentUser(user): Promise<any> {
 
         this.storage.set('currentUser', user);
         return  this.storage.set('token', user.token);
 
       }*/

    signup(model) {
        ////console.log(model);
        model.language = 'english';
        return this.http.post<any>(this.apiUrl + '/auth/signup', model).pipe(
            map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    if (this.platform.is('cordova')) {
                        //   window["plugins"].OneSignal.setExternalUserId(user.id);
                        //this.firebaseService.updateUserTags();
                    }

                    this.firebaseService.logbfevent('sign_up', { method: 'mobile' });
                    //  this.commonService.FirebaseService.setfbuserid(data.id);
                    //                          this.firebaseService.setfbuserid(user.id);
                    this.firebaseService.setfbuserid(user);



                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.commonService.setCurrentUser(user);
                    localStorage.setItem('token', user.token);
                    this.commonService.apiToken = user.token;

                    //publish events
                    this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
                        // this.setCurrentUser(user);
                        // return this.events.publish('user:login');
                    });
                }
                return user;
            }));
    }

    changePassword(model) {
        return this.http.post<any>(this.apiUrl + '/user/changepassword', model).pipe(
            map(user => {

                return user;
            }));
    }


    /* logout() {
         // remove user from local storage to log user out
         localStorage.removeItem('currentUser');
         localStorage.removeItem('token');
         //not applicable in ionic app
         // localStorage.clear();
     }*/
    logout(): Promise<any> {
        return this.storage.remove(this.HAS_LOGGED_IN).then(() => {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('token');
            localStorage.removeItem('cartitem');
            localStorage.removeItem('menuitem');
            localStorage.removeItem('popupregister');
            localStorage.removeItem('fullprofile');
            localStorage.removeItem(this.commonService.currentUser.mobile+'cart');
            localStorage.removeItem('guestcart');

            // localStorage.removeItem('cuisines');
            //  localStorage.removeItem('homepagebanners');
            // localStorage.removeItem('foodfacts');
            // let m=localStorage.getItem('auth_mobile');
            // localStorage.clear();
            // localStorage.setItem('auth_mobile',m);
            this.commonService.currentUser = null;
            this.commonService.addresses = null;
            this.commonService.cart = null;
            //this.events.publish('user:logout');
        });
    }

    getCurrentUser() {
        //  const token = localStorage.getItem('token').trim();
        //   const decodedToken = jwt_decode(token);
        ////console.log(decodedToken);
        // return decodedToken.data;
        return this.commonService.getCurrentUser();
    }

    checkPermission(pid) {
        const cu = this.getCurrentUser();
        if (!cu) {
            return false;
        }
        if (!cu.role) {
            return false;
        }
        // //console.log(cu);
        //   //console.log(pid,cu.role.permissions);
        if (cu.role.allowall_super === '1') {
            return true;
        }

        if (cu.role.permissions) {
            const pus = cu.role.permissions.split(',');
            // //console.log(pus,pid.toString(),pus.includes(pid.toString()));
            return pus.includes(pid.toString());
        }
        //       return true;
    }

    refreshToken() {
        let appversion: any = -1;
       // this.firebaseService.updateUserTags();
        /*
        let firebasetoken=localStorage.getItem('firebasetoken');
        let appv=localStorage.getItem('appVersionNumber');*/
        let currentDevice = this.commonService.getDevice();
        let appv = currentDevice.appVersionNumber;
        let firebasetoken = currentDevice.firebasetoken;
        if (appv) {
            appversion = appv;
        }
        let params: any = {};
        //?firebasetoken='+firebasetoken+'&appversion='+appversion
        params.firebasetoken = firebasetoken;
        params.appversion = appversion;

        if (localStorage.getItem('onesignal')) {
            let os: any = JSON.parse(localStorage.getItem('onesignal'));
            if (os) {
                params.os_userid = os.userId;
                params.firebasetoken = os.pushToken;
            }
        }


        return this.http
            .get<any>(this.apiUrl + '/auth/refreshtoken', { params: params }).pipe(
                map(user => {

                    localStorage.setItem('token', user.token);
                    this.commonService.apiToken = user.token;
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.commonService.setCurrentUser(user);
                    //set language
                //    this.commonService.setAppLanguage(user.language);

                    this.firebaseService.logbfevent('refresh_token');
                    //  this.commonService.FirebaseService.setfbuserid(data.id);
                    //this.firebaseService.setfbuserid(user.id);
                    this.firebaseService.setfbuserid(user);

                    return user;
                }));
    }

}
