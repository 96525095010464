import { Component, Input ,Output,OnInit } from '@angular/core';
import { CommonService } from '../../../providers';
import { Router, ActivatedRoute } from '@angular/router';

@Component ({
    selector :'app-accordion-single',
    templateUrl: './accordion-single.html',
    styleUrls: ['./accordion-single.scss'],
})

export class AccordionsingleComponent  implements OnInit {

    @Input() options: any; 
    item:any={};
    public isMenuOpen : boolean = false;

    
    constructor(public commonService:CommonService,public router:Router) {

    }

    ngOnInit(): void {
        // //console.log(this.options);
        // this.items = ['nikhil', 'ganesh'];
        if(this.options){
            // //console.log(this.options);
           this.item=this.options.item;
           // //console.log(this.item);
        }
    }
    


  /**
   * Allows the accordion state to be toggled (I.e. opened/closed)
   * @public
   * @method toggleAccordion
   * @returns {none}
   */
  public toggleAccordion() : void
  {
      this.isMenuOpen = !this.isMenuOpen;
  }
 



}