import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs'; 
import { environment } from '../../environments/environment';
//import { FCM } from '@ionic-native/fcm/ngx';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {Platform} from '@ionic/angular';
//import { AngularFireAnalytics } from '@angular/fire/analytics';

declare const fbq;
declare const _paq;

@Injectable()
export class FirebaseService {

    device:any={};
    currentNotificationId:any='';
    constructor(public router:Router,//private fcm: FCM,
      //  public OneSignal:OneSignal,//public analytics: AngularFireAnalytics,
        public platform:Platform){

           // this.analytics.updateConfig({DEBUG_MODE:true});

    }

    logbfevent($ename,$data=null){
       // this.analytics.logEvent($ename, $data);
    }
    setfbuserid(user){
       
        if(user){
            let uid=user.name+' - '+user._id;
            if(window["plugin"]){
                window["plugins"].OneSignal.setExternalUserId(user._id ?? user.id);
                window["plugins"].OneSignal.setSMSNumber('+91'+user.mobile);    
            }
           
            //console.log('assign id '+uid.toString());
        //this.analytics.setUserId(uid.toString());
        _paq.push(['setUserId', uid.toString()]);
        }
    }

    //we may use it or not
    setcurrentscreenfb(sname){
        //depreciated for now
        //console.log(sname);
       //this.analytics.logEvent('page_view',{name:sname,class:sname,pagse_title:sname,screen_name:sname,screen_class:sname});

       setTimeout(()=>{
        var currentUrl = location.href;
        //currentUrl = '/' + window.location.hash.substr(1);
        //console.log(currentUrl,sname);
        _paq.push(['setCustomUrl', currentUrl]);
        _paq.push(['setDocumentTitle', sname]); 
    
        // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
        _paq.push(['deleteCustomVariables', 'page']); 
        _paq.push(['trackPageView']);
       },1000);

        
//         this.analytics.setCurrentScreen(sname);
    }

    

    sendTag(key,value){
        if (this.platform.is('cordova') && window["plugins"] ) {
            window["plugins"].OneSignal.sendTag(key,value)
        }
    }

    sendTags(data){
        if (this.platform.is('cordova') && window["plugins"] ) {
            window["plugins"].OneSignal.sendTags(data);
        }
    }

    

    showNotification(udata,notify=true,quick=false){
//        //console.log(data);
    let data:any={};        
            if(udata.payload){
                 data=udata.payload;
              } else if(udata.notification){
                data=udata.notification.payload;
              }
        //console.log('notificationID',data.notificationID);      

       if(this.currentNotificationId == data.notificationID){
           //console.log('Supressing double click');
           return 0;
       }
              
       this.currentNotificationId=data.notificationID;
       // //console.log(JSON.stringify(data));
        if(data.message){
            data.body=data.message;
        }

        if(data.additionalData){
            if(data.additionalData.action){
                data.action=data.additionalData.action;
            }
        }

       // //console.log(data);
      

        if(data.additionalData){
            if(data.additionalData.appaction){
                data.action=data.additionalData.appaction;
            }
        }

        //console.log(data);

        if(data.title  && notify && data.action!='alert'){
           // this.alertService.notify('info',data.title,data.body);
            }

        if(data.action){
            let salt=Math.floor(Math.random() * Math.floor(40000));

            switch(data.action){
                case 'checkneworder':
                    setTimeout(() => {
                        this.router.navigateByUrl('/supplier/order/showrequests/'+salt);
                    }, 2000);
                
                break;

                case 'customerorderready':
                
                setTimeout(() => {
                    this.router.navigateByUrl('/customer/order/orderready/'+salt);
                }, 2000);
                break;    

                case 'alert':
                    setTimeout(() => {
                      //   this.alertService.alert(data.title,data.body);
                    }, 2000);
                break;
                
                case 'gotomymaorders':
                    setTimeout(() => {
                        this.router.navigateByUrl('/supplier/order/'+salt);
                    }, 2000);

                break;
            }

            if(data.action.includes('static')){
                let timeout=2000;
                if(quick){
                    timeout=0;
                }
                setTimeout(() => {
                this.router.navigateByUrl('/shared/'+data.action);
            }, timeout);

            }

        }
    }

    
}
