import { Component, Input ,Output,OnInit ,EventEmitter,OnChanges, SimpleChanges, SimpleChange} from '@angular/core';


import { CommonService } from '../../../providers';
import { Router, ActivatedRoute } from '@angular/router';

@Component ({
    selector :'app-pmcounter-single',
    templateUrl: 'pmcounter.html',
    styleUrls: ['./pmcounter.scss'],
})

export class PmcounterComponent implements OnInit ,OnChanges {

    @Input() options: any;
    @Output() Outputquantity=new EventEmitter<any>();
    item:any={};
    mode:any;
    model:any={};
    min=0;
    max=20;
    quantity=1;
    val:any=0;


    constructor(public commonService:CommonService,public router:Router) {


        }

        ngOnInit(): void {
            console.log(this.options);
            // this.items = ['nikhil', 'ganesh'];
            if(this.options){
    
                // this.item=this.options.item;
                // this.mode=this.options.mode;
                // this.mode=='customer'?this.item.backaction="/customer/homecustomer":this.item.backaction="/supplier/menu";
                // //if(this.options.min){

                if(this.options.item){
                    this.item=this.options.item;
                }
                if(this.options.min){
                    this.min=this.options.min;
                }
                if(this.options.max){
                    this.max=this.options.max;
                 }
                if(this.options.val){
                    this.quantity=this.options.val;
                } else{
                    this.quantity=this.min;
                }
                // }
            }
        }

        ngOnChanges(changes: SimpleChanges) {

            const currentItem: SimpleChange = changes.options;
            ////console.log(changes);
            if(currentItem){
               // //console.log('prev value: ', currentItem.previousValue);
            ////console.log('got item: ', currentItem.currentValue);
            //  this.scannedUPC = changes.item.currentValue.upc;
                this.quantity=currentItem.currentValue.val;
            }
          }


        ViewMenu(item){
            this.commonService.setStorageObject('menuitem',item);
           this.router.navigateByUrl('/'+this.mode+'/shared/menuview');
        }

        ViewOrders(item){
            this.commonService.setStorageObject('ordermenuitem',item);
           this.router.navigateByUrl('/supplier/shared/orderview');
           //console.log('/supplier/shared/orderview');
        }

        plusminus(action){
            // console.log('1');
            if(this.item._id && !this.commonService.cart_isincart(this.item)){
                // console.log('2');
                this.commonService.cart_add(this.item);
            }
            //if(model){}else{model=1;}
            action=='+'?this.quantity++:this.quantity--;

            if(this.quantity>0){
                if(this.quantity>this.max){
                    this.quantity=this.max;
                    this.commonService.notify('error','You cannot add more than '+this.max+' quantity of this product');
                }
            }else{
                this.quantity=this.min;
            }
            this.output();
//           return model;
           }

           /*plus(){
            this.quantity =this.plusminus('+');
           }
           minus(){
            this.quantity =this.plusminus('-');
           }*/

           output(){
               if(!this.quantity || this.quantity<1){
                   this.quantity=this.min;
               }

               if(this.quantity>0){
                    if(this.quantity>this.max){
                        this.quantity=this.max;
                        this.commonService.notify('error','You cannot add more than '+this.max+' quantity of this product');
                    }      
                }
                console.log(this.quantity);
                this.Outputquantity.emit(this.quantity);
           }
}
