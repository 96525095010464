
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { CommonService } from './common.service';

@Injectable()
export class UserService {
    apiUrl: string = environment.apiUrl;
    RapiUrl: string = environment.RapiUrl;
    constructor(private http: HttpClient, public commonService: CommonService) { }

    pingLocation() {

        let model: any = {};
        //model.uuid=localStorage.getItem('uuid');
        if (this.commonService.currentDevice) {
            model.uuid = this.commonService.currentDevice.uuid;
        } else {
            model.uuid = localStorage.getItem('uuid');
            this.commonService.currentDevice.uuid = model.uuid;
        }
        let cu = this.commonService.getCurrentUser();
        if (cu) {
            model.user_id = cu.id;
        }
        //console.log('location PingSserive');
        if (localStorage.getItem('location_changed') == "0") {
            //console.log('location no change dont send ping');
            return 0;
        }
        //console.log('location  change send ping');
        localStorage.setItem('location_changed', "0");
        let location = localStorage.getItem('location');
        if (location) {
            let g: any = JSON.parse(location);
            model.lat = g.position.lat;
            model.lng = g.position.lng;
            model.formatted_address = g.formatted_address;
            if (g.parsedaddress) {

                model.state = g.parsedaddress.state;

                model.city = g.parsedaddress.city;

                if (g.parsedaddress.locality) {
                    model.locality = g.parsedaddress.locality;
                }

                if (g.parsedaddress.pincode) {
                    model.pincode = g.parsedaddress.pincode;
                }

            }

            let r = Math.floor(Math.random() * 10) + 1;
            setTimeout(() => {
                let o = this.http.post<any>(this.apiUrl + '/user/pinglocation', model).subscribe((res) => {
                });
            }, 1000 * r);



        }


    }



    updateprofilepic(model) {
        return this.http.post<any>(this.apiUrl + '/user/updateprofilepic', model).pipe(
            map(user => {
                //   //console.log(quotation);
                return user;
            }));
    }



    /*
    list() {
    
        return this.http
        .get<any>(this.RapiUrl + '/user/list').pipe(
        map(res => {
            //   //console.log(quotation);
                sessionStorage.setItem('users',JSON.stringify(res));
                return res;
           }));
      }

      
       addEdit(selectedUser) {
       //console.log(selectedUser);
       return this.http.post<any>(this.apiUrl + '/user/addedit',  selectedUser ).pipe(
       map(user => {
        //   //console.log(quotation);
            return user;
       }));
      }*/

    updatePaymentDetails(pid) {
        return this.http.post<any>(this.apiUrl + '/supplier/updatepaymentdetails', pid).pipe(
            map(user => {
                //   //console.log(quotation);
                return user;
            }));
    }

    fssaiupdate(pid) {
        return this.http.post<any>(this.apiUrl + '/supplier/updatefssaidetails', pid).pipe(
            map(user => {
                //   //console.log(quotation);
                return user;
            }));
    }

    kycupdate(pid) {
        return this.http.post<any>(this.apiUrl + '/supplier/updatekycdetails', pid).pipe(
            map(user => {
                //   //console.log(quotation);
                return user;
            }));
    }

    guidelinesupdate(pid) {
        return this.http.post<any>(this.apiUrl + '/supplier/updateguidelinesdetails', pid).pipe(
            map(user => {
                //   //console.log(quotation);
                return user;
            }));
    }


    contactform(pid) {
        return this.http.post<any>(this.apiUrl + '/customer/contactform', pid).pipe(
            map(user => {
                //   //console.log(quotation);
                return user;
            }));
    }

    supplier_home_data() {
        return this.http.get<any>(this.apiUrl + '/supplier/homedata').pipe(
            map(user => {
                //   //console.log(quotation);
                return user;
            }));
    }



    supplierreviews(id) {
        return this.http.get<any>(this.apiUrl + '/customer/supplierreviews?id=' + id).pipe(
            map(user => {
                //   //console.log(quotation);
                //return user;
                return user;
            }));
    }

    orderreview(id) {
        return this.http.post<any>(this.apiUrl + '/supplier/orderreviewaddedit', id).pipe(
            map(user => {
                //   //console.log(quotation);
                //return user;
                return user;
            }));
    }
    checkpendingreviews() {
        return this.http.get<any>(this.apiUrl + '/customer/checkpendingreviews?salt=' + Math.random()).pipe(
            map(user => {
                //   //console.log(quotation);
                //return user;
                return user;
            }));
    }

    checkpendingorderactions() {
        return this.http.get<any>(this.apiUrl + '/supplier/checkpendingorderactions?salt=' + Math.random()).pipe(
            map(user => {
                //   //console.log(quotation);
                //return user;
                return user;
            }));
    }

    supplierpendingorderactions() {
        return this.http.get<any>(this.apiUrl + '/supplier/pendingorderactions?salt=' + Math.random()).pipe(
            map(user => {
                //   //console.log(quotation);
                //return user;
                return user;
            }));
    }

    cashfree_generaterequest(params) {
        return this.http.post<any>(this.apiUrl + '/cashfree/generaterequest', params).pipe(
            map(user => {
                //   //console.log(quotation);
                //return user;
                return user;
            }));
    }



}
