import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';


//Plugins
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { IonicStorageModule } from '@ionic/storage-angular';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './providers/token.interceptor';
import { AuthGuard } from './providers/auth.guard';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BnNgIdleService } from 'bn-ng-idle';
import { DatePipe } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';

export function dataProviderFactory(dataService: BackendService) {
    return () => dataService.getAppSettings();
  }

//Services
import {
  OtherService,
  AuthService,
  FirebaseService,
  CommonService,
  UserService,
  BackendService,
  ApiService,
  HelperService
} from './providers/index';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { NetworkService } from './providers/network.service';
import { Network } from '@ionic-native/network/ngx';


@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, IonicModule.forRoot(),
        HttpClientModule,
        
        IonicModule.forRoot({
            mode: 'md',
        }),
        
        IonicStorageModule.forRoot(),
        SharedModule,
        AppRoutingModule, ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),ScullyLibModule, ],
      
    providers: [
        SplashScreen,
        Geolocation, InAppBrowser,
        AuthService, AuthGuard,
        ApiService,
        FirebaseService,
        CommonService,
        UserService,
        OtherService,
        BackendService,
        { provide: APP_INITIALIZER, useFactory: dataProviderFactory, deps: [BackendService], multi: true },
        DatePipe,
        HelperService,
        BnNgIdleService, 
        Network,
        NetworkService,
        PhotoViewer,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
