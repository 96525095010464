import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

import { HelperService } from './helper.service';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    apiUrl: string = environment.apiUrl;

    canSyncDBCache = true;
    flag_refreshlist = false;
    tempdata: any = {};


    metadata: any = {
        bloodgroups: [{ name: 'A+' }, { name: 'B+' }, { name: 'AB+' }, { name: 'O+' }, { name: 'A-' }, { name: 'B-' }, { name: 'AB-' }, { name: 'O-' }],
        maritalstatuses: [{ name: 'Married' }, { name: 'Single' }, { name: 'Divorced' }, { name: 'Other' }],
        genders: [{ name: 'Male' }, { name: 'Female' }, { name: 'Other' }]
    };

    DBCache: any = {};
    syncCaches = [
        { cache_key: 'DEPARTMENTS' }, { cache_key: 'BRANCHES' }
    ];
    //API Definitions
    APIS: any = {
        AUTH_LOGIN: { endpoint: 'auth/login', method: 'post' },
        AUTH_SIGNUP: { endpoint: 'auth/signup', method: 'post' },
        AUTH_GENERATEOTP: { endpoint: 'auth/generateotp', method: 'post' },
        AUTH_VALIDATEOTP: { endpoint: 'auth/validateotp', method: 'post' },
        FILE_UPLOAD: { endpoint: 'file/upload', method: 'post' },
        OTHER_APPSETTINGS: { endpoint: 'others/appsettings', method: 'get' },
        MASTER_LIST: { endpoint: 'master/list', method: 'get' },
        USER_PROFILE: { endpoint: 'user/fullprofile', method: 'get' },
        USER_LIST: { endpoint: 'user/list', method: 'get' },
        USER_ADDEDIT: { endpoint: 'user/addedit', method: 'post' },
        USER_NOTIFICATION: { endpoint: 'user/notifications', method: 'get' },
        ADDRESS_ADDEDIT: { endpoint: 'address/addedit', method: 'post' },
        ADDRESS_DELETE: { endpoint: 'address/deletaddress', method: 'post' },

        BLOG_CATEGORY_LIST: { endpoint: 'blogcategory/list', method: 'get' },
        BLOG_LIST: { endpoint: 'blog/list', method: 'get' },
        ADMIN_SERVERTIME: { endpoint: 'others/serverTime', method: 'get' },
        ADMIN_AVAILABLETIMESLOTS: { endpoint: 'others/AvailableTimeSlots', method: 'get' },
        ADMIN_DELIVERYCHARGES: { endpoint: 'others/deliverycharges', method: 'get' },
        ADMIN_OFFERCODELIST: { endpoint: 'offer/list', method: 'get' },
        ADMIN_APPLYCC: { endpoint: 'others/Applycouponcode', method: 'post' },
        ADMIN_APPLYOC: { endpoint: 'others/Applyoffercode', method: 'post' },
        ORDER_ADDEDIT: { endpoint: 'order/addedit', method: 'post' },
        ORDER_GENERATE: { endpoint: 'order/generate', method: 'post' },
        ORDER_LIST: { endpoint: 'order/list', method: 'get' },
        ORDER_CHECKORDERSTATUS: { endpoint: 'order/checkorderstatus', method: 'get' },
        INVENTORY_LIST: { endpoint: 'others/inventorylist', method: 'get' },
        ORDER_CANCEL: { endpoint: 'order/cancel', method: 'post' },
        MASTER_MUTTON_AVAILABILITY: { endpoint: 'master/mutton_availability_list', method: 'get' },

        ///order/verifyOrderOtp
        ///user/pinglocation

        AREACODE_LIST: { endpoint: 'areacode/list', method: 'get' },


    }

    constructor(private http: HttpClient, public helperService: HelperService //public events: Events,
    ) { }

    callapi(api: any, params = null, CACHE_KEY = null):Observable<any> {
        //console.log(api);
        switch (api.method) {
            case 'get':
            //console.log(this.apiUrl + '/' + api.endpoint);
                return this.http.get<any>(this.apiUrl + '/' + api.endpoint, { params: params }).pipe(
                    map(data => {
                      //  //console.log(data);
                        //this.helperService.storage_set('cache:'+api.endpoint,data);
                        if(CACHE_KEY){this.upsertDBcache(CACHE_KEY, data?.data ? data.data : data);}
                        return data;
                    }));
                break;

            case 'post':
                return this.http.post<any>(this.apiUrl + '/' + api.endpoint, params).pipe(
                    map(data => {
                        return data;
                    }));
                break;
        }
    }

    upsertDBcache(CACHE_KEY = null, data: any) {

        if (CACHE_KEY && this.helperService.isBrowser) {
            //console.log('Upsert DB Cache : ' + CACHE_KEY);
            this.helperService.storage_set('DBCache:' + CACHE_KEY, data).then(() => {
                this.DBCache[CACHE_KEY] = data;
            });
        }
    }

    refreshDBcache() {
        //console.log('Refreshing DB Cache');
        this.syncCaches.forEach(current => {
            this.helperService.storage_get('DBCache:' + current.cache_key).then(data => {
                this.DBCache[current.cache_key] = data;
            });
        });
    }

    syncDBCache() {
        this.canSyncDBCache = false;
        //console.log('Syncing DB Cache');
        this.syncCaches.forEach(current => {
            // this.callapi(this.PAGES[current.page_key].apis.list, {}, current.page_key).subscribe();
        });
    }


    uploadFile(file, reportProgress = false) {
        // //console.log(file);
        let formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post<any>(this.apiUrl + '/' + this.APIS.FILE_UPLOAD.endpoint, formData).pipe(
            map(res => res));
    }

    getMuttonAvailabilityList(): Observable<any> {
        return this.http.get(`${this.apiUrl}/master/mutton_availability_list`);
      }


    getObjFromDBCache(CacheName: string, _id: string) {
        let object: any = {};
        // //console.log(CacheName,_id)
        for (let obj of this.DBCache[CacheName]) {
            if (obj?._id == _id) {
                object = obj;
                ////console.log(JSON.stringify(obj));
                break;
            }
        }
        return object;
    }

    
}
