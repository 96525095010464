export const environment = {
   production: false,
  apiUrl: 'https://api-dev.meatright.in',
  RapiUrl: 'https://api-dev.meatright.in',
  // apiUrl: 'http://localhost:3000',
  // RapiUrl: 'http://localhost:3000',
  firebase: {
    apiKey: "AIzaSyABhwGWiyf1tbtT0LgK90A43JUgqgPmX60",
    authDomain: "meatright-bf1e0.firebaseapp.com",
    databaseURL: "https://meatright-bf1e0.firebaseio.com",
    projectId: "meatright-bf1e0",
    storageBucket: "meatright-bf1e0.appspot.com",
    messagingSenderId: "669810029358",
    appId: "1:669810029358:web:853652f52a7071329b00bf",
    measurementId: "G-ELXX7MMWD1"
  }
};