import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { CommonService } from './common.service';
import { UserService } from './user.service';
import { ToastController, AlertController, LoadingController } from '@ionic/angular';
 
import { ApiService } from './api.service';
declare var jQuery: any;

@Injectable()
export class OtherService {
  apiUrl: string = environment.apiUrl;
  RapiUrl: string = environment.RapiUrl;
  globalappdata: any = {};
  constructor(private http: HttpClient,
    public storage: Storage, public commonService: CommonService, public userService: UserService, public apiService: ApiService) { }


  getRemoteSettingsdata($var = 'appsettings') {
    let appsettings: any;

    //console.log('delivery Charge grs');

    if (this.globalappdata) {

      if (this.globalappdata[$var]) {

        return appsettings = this.globalappdata[$var];
      }
    } else {
      //check localstorage
      let appsettingsjson = localStorage.getItem($var);
      if (appsettingsjson) {
        return appsettings = JSON.parse(appsettingsjson);

      }
    }

  }


  getAppSettings() {
    let params: any = {};
    params.d = 1;

    let r = 1;
    r = Math.floor(Math.random() * 2) + 1;

    //Fetch Core App Settings
    //console.log('delivery Charge ');

    setTimeout(() => {
      let cuisines = this.http.get<any>(this.RapiUrl + '/other/appsettings', { params: params }).subscribe((res) => {
        //  //console.log(res);
        //  this.commonService.setStorageObject('appettings',res);
        //localStorage.setItem('cuisines',JSON.stringify(res));
        this.globalappdata.appsettings = res;
        if (res.mincartvalueforfreedelivery) {
          // this.globalappdata.mincartvalueforfreedelivery=res.mincartvalueforfreedelivery;
          this.commonService.mincartvalueforfreedelivery = res.mincartvalueforfreedelivery;
          //console.log('delivery Charge ' + res.delivery_charge);
          this.commonService.delivery_charge = res.delivery_charge;
        }
        //for backup we also set this as localStorage Object
        localStorage.setItem('appsettings', JSON.stringify(res));

      });
    }, 1000 * r);

  }

  getBasicData() {
    let params: any = {};
   // params.language = this.commonService.getAppLanguage();
   params.language='english';
    params.d = 1;

    let r = 1;

    /* r= Math.floor(Math.random() * 10) + 1;
     setTimeout(() => {
         let homepagebanners= this.http.get<any>(this.RapiUrl + '/customer/homepagebanners',{params:params}).subscribe((res)=>{
             //            //console.log(res);
                         localStorage.setItem('homepagebanners',JSON.stringify(res));
                     }); 
     }, 1000*r);
     */

    r = Math.floor(Math.random() * 1) + 1;
    if (this.commonService.getCurrentUser()) {
      setTimeout(() => {
        this.apiService.callapi(this.apiService.APIS.USER_PROFILE).subscribe(
          data => {
          },
          error => {
          });
      }, 100 * r);
    }




  }


  checkappaupdate(params) {

    return this.http
      .get<any>(this.RapiUrl + '/other/appupdate', { params: params }).pipe(
        map(res => {
          return res;
        }));
  }





}