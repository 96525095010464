import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { CommonService } from './common.service';
import { ToastController, AlertController, LoadingController, Platform } from '@ionic/angular'; 
 
import { FirebaseService } from './firebase.service';
declare var jQuery: any;

@Injectable()
export class BackendService {
  apiUrl: string = environment.apiUrl;
  RapiUrl: string = environment.RapiUrl;
  globalappdata: any = {};
  HAS_LOGGED_IN = 'hasLoggedIn';
  constructor(private http: HttpClient, public platform: Platform,
    public firebaseService: FirebaseService, public commonService: CommonService) {

  }

  getRemoteSettingsdata($var = 'appsettings') {
    //console.log("abc");
    let appsettings: any;
    if (this.globalappdata && this.globalappdata.length > 0) {
     
      if (this.globalappdata[$var]) {

        return appsettings = this.globalappdata[$var];
      }
    } else {
      //check localstorage
     
      let appsettingsjson = localStorage.getItem($var);
      if (appsettingsjson) {
        return appsettings = JSON.parse(appsettingsjson);

      }
    }

  }

  setAppSettings(res){
    this.globalappdata.appsettings = res; localStorage.setItem('appsettings', JSON.stringify(res));

        if (res.mincartvalueforfreedelivery) {
          // this.globalappdata.mincartvalueforfreedelivery=res.mincartvalueforfreedelivery;
          this.commonService.mincartvalueforfreedelivery = res.mincartvalueforfreedelivery;
          //console.log('delivery Charge ' + res.delivery_charge);
          this.commonService.delivery_charge = res.delivery_charge;
        }
  }

  //Depreciated - No Longer in Use
  getAppSettings() {
    let params: any = {};
    params.d = 1; let r = 1;
    r = Math.floor(Math.random() * 2) + 1; setTimeout(() => {
      let cuisines = this.http.get<any>(this.RapiUrl + '/others/appsettings', { params: params }).subscribe((res) => {
        this.globalappdata.appsettings = res; localStorage.setItem('appsettings', JSON.stringify(res));

        if (res.mincartvalueforfreedelivery) {
          // this.globalappdata.mincartvalueforfreedelivery=res.mincartvalueforfreedelivery;
          this.commonService.mincartvalueforfreedelivery = res.mincartvalueforfreedelivery;
          //console.log('delivery Charge ' + res.delivery_charge);
          this.commonService.delivery_charge = res.delivery_charge;
        }

      });
    }, 1000 * r);
  }

  verifyOTP(model) {
    return this.http.post<any>(this.apiUrl + '/order/verifyorderotp', model).pipe(
      map(data => {
        //let user = data.user;
        // login successful if there's a jwt token in the response
        if (data && data.token) {
          if (this.platform.is('cordova')) {
            //window["plugins"].OneSignal.setExternalUserId(user.id);
          //  this.firebaseService.updateUserTags();
          }
          this.firebaseService.logbfevent('login', { method: 'mobile' });
          //  this.commonService.FirebaseService.setfbuserid(data.id);
          this.firebaseService.setfbuserid(data);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.commonService.setCurrentUser(data);
          localStorage.setItem('token', data.token);
          this.commonService.apiToken = data.token;

          
          let usermobile = 'guest';
          console.log(data);
         
            usermobile = data.mobile;
          
        
            this.commonService.cart = { items: []};
                      
          

            if(localStorage.getItem('guestcart')){
                let lcart=localStorage.getItem('guestcart');
                localStorage.setItem(usermobile+'cart', lcart);
                console.log(this.commonService.cart,);
                console.log(JSON.parse(lcart));
                this.commonService.cart=JSON.parse(lcart);
                localStorage.removeItem('guestcart');

            }
          
     
        }
        return data;
      }));
  }
}